import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Container, Row, Col } from 'react-bootstrap';

function App() {
  return (
    <div className="showcase">
      <Container>
        <Row>
          <Col xs={12} className="d-flex flex-column align-items-center">
            <div className="barcode">
              <img src={logo} alt="Logo" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
